<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="地图名称" prop="mapName">
                <a-input v-model="queryParam.mapName" placeholder="请输入地图名称" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="车道名称" prop="laneName">
                <a-input v-model="queryParam.laneName" placeholder="请输入车道名称" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:iot:raLane:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tenant:iot:raLane:remove']">
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:raLane:export']">
            <a-icon type="download" />导出
          </a-button>
          <a-button
            type="primary"
            @click="$refs.importExcel.importExcelHandleOpen()"
            v-hasPermi="['tenant:iot:raLane:export']"
          >
            <a-icon type="import" />导入车道数据
          </a-button>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <import-excel ref="importExcel" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:raLane:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:raLane:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:raLane:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:raLane:remove']"> <a-icon type="delete" />删除 </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listRaLane, delRaLane, exportRaLane, syncMapdataToLane } from '@/api/iot/raLane'
import CreateForm from './modules/CreateForm'
import ImportExcel from './modules/ImportExcel'

export default {
  name: 'RaLane',
  components: {
    CreateForm,
    ImportExcel
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mapName: null,
        laneName: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车道ID',
          dataIndex: 'laneId',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: 'mongoDB自动生成的ID',
        //   dataIndex: 'objectId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '车道类型',
          dataIndex: 'laneType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '地图名称',
          dataIndex: 'mapName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车道名称',
          dataIndex: 'laneName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车道长度',
          dataIndex: 'laneLength',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询车道管理列表 */
    getList() {
      this.loading = true
      listRaLane(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        mapName: undefined,
        laneName: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delRaLane(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportRaLane(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    handleImport() {
      // 导入操作
      syncMapdataToLane().then((response) => {
        this.$message.success('导入成功', 3)
      })
    }
  }
}
</script>
